const domain = process.env.REACT_APP_DOMAIN_COOKIE ? process.env.REACT_APP_DOMAIN_COOKIE : '';
const path = new RegExp('https://').test(window.location.href);

export const create = (name, value, hr) => {
  try {
    if (name && value) {
      const dt = new Date();
      if (hr) {
        dt.setTime(dt.getTime() + (3600000 * hr));
      } else {
        dt.setTime(dt.getTime() + 3600000);
      }
      return document.cookie = `${name}=${value}; expires=${hr ? dt.toUTCString() : ''}; domain=${domain}; path=/${path ? '; secure' : ''}`;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const get = name => {
  try {
    const parts = `; ${document.cookie}`.split(`; ${name}=`);
    if (name && parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const remove = name => {
  try {
    if (name) {
      return document.cookie = `${name}=; expires=${new Date(new Date().setDate(new Date().getDate() - 1)).toUTCString()}; domain=${domain}; path=/${path ? '; secure' : ''}`;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeAll = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;  domain=${domain}; path=/${path ? '; secure' : ''}`;
  }
};

export default { create, get, remove, removeAll };
