import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchProfile, fetchLogout } from './../stores/Actions';
import { checkNull } from '../commons/Function';
import '../scss/Profile.scss';

const Profile = () => {
  const [profile, setProfile] = useState({
    name: null,
    organization: {
      name: null,
    },
  });
  const [roles, setRoles] = useState([]);
  const getProfile = useSelector(({ getProfile }) => getProfile.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getProfile) {
      const getRoles = getProfile.userRoles.map(item => item.role.name);
      
      setProfile(getProfile);
      setRoles(getRoles);
    } else {
      dispatch(fetchProfile());
    }
  }, [getProfile]);

  const toggleLogout = async () => {
    dispatch(fetchLogout());
  };

  return (
    <>
      <div className="title">Profile</div>
      <div className="box-content">
        <div className="profile-avatar">
          <div className="avatar"></div>
          <div className="info">
            <label>Name</label>
            <p>{checkNull(profile.name)}</p>
            <small>Status: Active</small>
          </div>
        </div>
        {
          roles ? roles.map((item, key) => (
            <div key={key} className="info-from-control">
              <small>Role</small>
              <p>{item}</p>
            </div>
          )) : null
        }
        <div className="info-from-control">
          <small>Organization</small>
          <p>{checkNull(profile.organization.name)}</p>
        </div>
      </div>
      <div className="box-content">
        <small>Change language</small>
        <div className="from-control">
          <select name="language">
            <option defaultValue>English</option>
          </select>
        </div>
        <div className="from-control">
          <button disabled>Submit</button>
        </div>
      </div>
      <div onClick={toggleLogout} className="box-logout">
        <i className="icon-logout" /> LOGOUT
      </div>
    </>
  );
};

export default Profile;
