import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const Loading = () => {
  const getLoading = useSelector(state => _.findKey(state , ['status', 'PENDING']));

  useEffect(() => {
    if (getLoading) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [getLoading]);
  return (
    <>
      {getLoading ?
        <div className="Loading">
          <div className="box-load"><img src="/images/loading.gif" alt="" /></div>
        </div> : null}
    </>
  );
};

export default Loading;
