import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Dashboard, OrderTrackings, OrderTrackingDetail, Profile, Warning, Search } from './pages';
import { MainTmps, Navbar } from './components';

const RoutePages = () => (
  <MainTmps>
    <div className="container">
      <Switch>
        <Route sensitive strict exact path="/dashboard" component={Dashboard} />
        <Route sensitive strict exact path="/order-tracking" component={OrderTrackings} />
        <Route sensitive strict exact path="/order-tracking/:orderNumber" component={OrderTrackingDetail} />
        <Route sensitive strict exact path="/search" component={Search} />
        <Route sensitive strict exact path="/profile" component={Profile} />
        <Route sensitive strict exact path="/warning" component={Warning} />
        <Redirect strict from="/" to="/dashboard" />
      </Switch>
    </div>
    <Navbar />
  </MainTmps>
);

export default RoutePages;
