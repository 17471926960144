import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { BoxTmps } from './../components';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLogin, fetchProfile } from './../stores/Actions';
import { create } from '../commons/Cookies';

const Login = () => {
  const [checkLogin, setCheckLogin] = useState(false);
  const [profile, setProfile] = useState();
  const [login, setLogin] = useState({ email: '', password: '', ttl: 10800 });
  const getLogin = useSelector(({ getLogin }) => getLogin);
  const getProfile = useSelector(({ getProfile }) => getProfile.data);
  const dispatch = useDispatch();
  const vChange = (e, key) => setLogin(Object.assign(login, { [key]: e.target.value }));
  const toggleLogin = () => dispatch(fetchLogin(login));
  
  useEffect(() => {
    if (getLogin && getLogin.data && getLogin.data.token) {
      setCheckLogin(false);
      dispatch(fetchProfile());
    }
    if (getLogin && getLogin.status === 401) {
      setCheckLogin(true);
    }
  }, [getLogin]);
  useEffect(() => {
    if (getProfile && getProfile.userId) {
      setClient(getProfile);
    }
  },[getProfile]);
  
  const setClient = async data => {
    const clients = await data.userRoles.map(item => item.role.organization.code);
    await create('clients', clients.join(), 3);
    await setProfile(data);
  };

  return profile && profile.userId 
    ? <Redirect to="/dashboard" /> 
    : <BoxTmps>
      <div className="Login">
        <div className="title">LOGIN</div>
        { checkLogin ? <div className="status-login">อีเมลหรือรหัสผ่านผิด</div> : null }
        <div className="from-ctrl">
          <input type="text" onChange={e => vChange(e, 'email')} placeholder="Email"/>
        </div>
        <div className="from-ctrl">
          <input type="password" onChange={e => vChange(e, 'password')} placeholder="Password"/>
        </div>
        <div className="forgot-link">
          <Link to="/forgot-password">forgot password</Link>
        </div>
        <button onClick={toggleLogin}>Login</button>
      </div>
    </BoxTmps>;
};

export default Login;
