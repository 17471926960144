import React from 'react';
import PropTypes from 'prop-types';

const BoxTmps = ({ children }) => (
  <div className="BoxTmps">
    <div className="container">
      <div className="logo">
        <img src="./images/logo.png" alt="" />
      </div>
      <div className="content-box">
        {children}
      </div>
    </div>
  </div>
);

BoxTmps.prototype = {
  children: PropTypes.children,
};

export default BoxTmps;
