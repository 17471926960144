import { combineReducers } from 'redux';
import Type from './Types';

function checkAction(state, action, type) {
  switch (action.type) {
    case type.success:
    case type.failure:
    case type.loading:
      return Object.assign({}, state, { ...action });
    default:
      return state;
  }
}

const getLogin = (state = {}, action) => checkAction(state, action, Type.login);
const getLogout = (state = {}, action) => checkAction(state, action, Type.logout);
const getProfile = (state = {}, action) => checkAction(state, action, Type.profile);
const getForgot = (state = {}, action) => checkAction(state, action, Type.forgot);
const getOrderTracking = (state = {}, action) => checkAction(state, action, Type.orderTracking);
const getWebTracking = (state = {}, action) => checkAction(state, action, Type.webTracking);
const getOrderDetails = (state = {}, action) => checkAction(state, action, Type.orderDetails);

export default combineReducers({
  getLogin, getLogout, getProfile, getOrderTracking, getWebTracking, getOrderDetails, getForgot,
});
