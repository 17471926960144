import Services from './Services';
import Type from './Types';
import Vars from './../commons/Variables';
import Cookie from './../commons/Cookies';

export const fetchLogin = params => dispatch => { Services.login(dispatch, Type.login, `${Vars.urlUserServices}/users/login`, params); };
export const fetchLogout = () => dispatch => { Services.post(dispatch, Type.logout, `${Vars.urlUserServices}/users/refresh-token`, { ttl: 0 }, true); };
export const fetchProfile = () => dispatch => { Services.get(dispatch, Type.profile, `${Vars.urlUserServices}/users/me?access_token=${Cookie.get('token')}`); };
export const fetchForgot = email => dispatch => { Services.post(dispatch, Type.forgot, `${Vars.urlUserServices}/users/forgot-password`, { email }); };
export const fetchOrderTracking = (tracking, orderDate, currPage) => dispatch => { Services.post(dispatch, Type.orderTracking, Vars.urlYAS, Vars.paramsOrderTracking(tracking, orderDate, currPage)); };
export const fetchWebTracking = tracking => dispatch => { Services.post(dispatch, Type.webTracking, Vars.urlYAS, Vars.paramsWebTracking(tracking)); };
export const fetchOrderDetails = tracking => dispatch => { Services.post(dispatch, Type.orderDetails, Vars.urlYAS, Vars.paramsOrderDetails(tracking)); };

export default {
  fetchLogin,
  fetchLogout,
  fetchProfile,
  fetchOrderTracking,
};
