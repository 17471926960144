import React from 'react';
import { Link } from 'react-router-dom';

const Warning = () => (
  <div className="Warning">
    <i className="icon-undelivered" />
    <div className="w-title">Oop! Something went wrong</div>
    <div className="w-detail">Sorry, we can’t get the infomation right now. Please try again later</div>
    <Link to="/" className="btn">BACK TO HOMEPAGE</Link>
  </div>
);

export default Warning;
