import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { BoxTmps } from './../components';
import { fetchForgot } from './../stores/Actions';

const ForgotPassword = () => {
  const [send, setSend] = useState(true);
  const [cError, setcError] = useState(false);
  const [email, setEmail] = useState('');
  const getForgot = useSelector(({ getForgot }) => getForgot);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getForgot && getForgot.status === 400) {
      setcError(true);
    } else if (getForgot && getForgot.status === 200) {
      setSend(false);
    }
  }, [getForgot]);

  const headerForget = () => {
    const exMail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/gm;
    if (exMail.test(email)) {
      setcError(false);
      dispatch(fetchForgot(email));
    } else {
      setcError(true);
    }
  };
  const ForgetPassword = () => (
    <div className="ForgotPassword">
      <div className="title">FORGET PASSWORD</div>
      <p className="">
        Please type your email. The system will send a new password to your email.
      </p>
      {
        cError ? <p className="text-error">Email Error Something</p> : null
      }
      <div className="from-ctrl mb-3">
        <input type="email" onChange={e => setEmail(e.target.value)} placeholder="Email" />
      </div>
      <button onClick={headerForget}>SEND</button>
      <div className="link-goback">
        <Link to="/">Back to login</Link>
      </div>
    </div>
  );
  const SendedMail = () => (
    <div className="ForgotPassword">
      <div className="title">CHECK YOUR EMAIL</div>
      <p className="">Please check your email to re-create password.</p>
      <Link to="/" className="btn"> BACK TO LOGIN</Link>
    </div>
  );
  return <BoxTmps>{send ? ForgetPassword() : SendedMail()}</BoxTmps>;
};

export default ForgotPassword;
