import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { formatDate, checkTrackingType, checkTrackingIcon } from '../commons/Function';

const Card = ({ orderNumber, qty, latestStaus, deliveryDate }) => {
  const history = useHistory();

  const toggleRedirect = orderTracking => {
    history.push(`order-tracking/${orderTracking}`);
  };

  return (
    <div onClick={() => toggleRedirect(orderNumber)} className={`box-item ${checkTrackingType(latestStaus)}`}>
      <div className="header">
        <div className="text-left">
          <div className="tracking-status">
            <i className={checkTrackingIcon(latestStaus)} />
          </div>
        </div>
        <div className="text-center">
          <p className="title">{orderNumber}</p>
          <p className="tracking-type">{latestStaus}</p>
        </div>
        <div className="text-right">
          <i className="icon-menu" />
        </div>
      </div>
      <div className="detail">
        <div className="text-left">
          <small>Item Qty.</small>
          <p>{qty}</p>
        </div>
        <div className="text-right">
          <small>Last updated</small>
          <p>{formatDate(deliveryDate)}</p>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  qty: PropTypes.string.isRequired,
  latestStaus: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
};

export default Card;
