import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'react-js-pagination';

import Vars from '../commons/Variables';
import { Card } from '../components';
import { currentDate } from './../commons/Function';
import { fetchOrderTracking } from './../stores/Actions';

const Dashboard = () => {
  const [orderTracking, setOrderTracking] = useState();
  const [currPage, setCurrPage] = useState(1);
  const getOrderTracking = useSelector(({ getOrderTracking }) => getOrderTracking.data);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchOrderTracking('', '', currPage));
  }, []);
  useEffect(() => {
    if (getOrderTracking) { setOrderTracking(getOrderTracking); }
  }, [getOrderTracking]);

  const pageChange = current => {
    setCurrPage(current);
    dispatch(fetchOrderTracking('', '', current));
  };
  
  return (
    <>
      <div className="title">Home</div>
      <div className="header">
        <div className="text-left">Tracking List ({orderTracking ? parseInt(orderTracking.totalRow[0].countRow).toLocaleString() : 0})</div>
        <div className="text-right">{currentDate()}</div>
      </div>

      {
        orderTracking ?
          <>
            {
              orderTracking ? orderTracking.data.map((item, key) => (
                <Card key={key} orderNumber={item.orderNumber} latestStaus={item.latestStaus} deliveryDate={item.deliveryDate} qty={item.qty} />
              )) : null
            }
            <div className="yas-pagination">
              <Pagination
                activePage={currPage}
                itemsCountPerPage={Vars.pagination.itemsCountPerPage}
                pageRangeDisplayed={Vars.pagination.pageRangeDisplayed}
                totalItemsCount={orderTracking ? orderTracking.totalRow[0].countRow : 0}
                onChange={pageChange}/>
            </div>
          </> : null
      }
    </>
  );
};

export default Dashboard;
