import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import RoutePages from './RoutePages';
import Cookie from './commons/Cookies';
import { Login, ForgotPassword } from "./pages";
import { Loading } from "./components";

import "./scss/App.scss";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Cookie.get('token')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )} />
)

const App = () => {
  return (
    <div className="App">
      <Router>
        <Loading />
        <Switch>
          <PrivateRoute exact path="/" component={RoutePages} />
          <PrivateRoute exact path="/dashboard" component={RoutePages} />
          <PrivateRoute exact path="/order-tracking" component={RoutePages} />
          <PrivateRoute exact path="/order-tracking/:orderNumber" component={RoutePages} />
          <PrivateRoute exact path="/search" component={RoutePages} />
          <PrivateRoute exact path="/profile" component={RoutePages} />
          <PrivateRoute exact path="/warning" component={RoutePages} />
          <Route sensitive strict exact path="/login" component={Login} />
          <Route sensitive strict exact path="/forgot-password" component={ForgotPassword} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
