import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'react-js-pagination';

import Vars from '../commons/Variables';
import { Card } from '../components';
import { currentDate } from './../commons/Function';
import { fetchOrderTracking, fetchWebTracking } from './../stores/Actions';

const initWebTracking = [{ latestStaus: '', awb: '', orderNumber: '', deliveryDate: '' }];

const OrderTrackings = () => {
  const [currPage, setCurrPage] = useState(1);
  const [orderTracking, setOrderTracking] = useState();
  const [tracking, setTracking] = useState();
  const [webTracking, setWebTracking] = useState(initWebTracking);
  const getOrderTracking = useSelector(({ getOrderTracking }) => getOrderTracking.data);
  const getWebTracking = useSelector(({ getWebTracking }) => getWebTracking.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type:'WEB_TRACKING_SUCCESS', data: null, status: 200 });
  }, []);
  useEffect(() => {
    if (getOrderTracking) { setOrderTracking(getOrderTracking); }
    else { dispatch(fetchOrderTracking('', '', currPage)); }
  }, [getOrderTracking]);
  useEffect(() => {
    if (getWebTracking && getWebTracking.data) { 
      setWebTracking(getWebTracking.data);
    } else {
      setWebTracking(initWebTracking);
    }
  }, [getWebTracking]);
  const toggleTracking = () => {
    if (tracking) {
      setCurrPage(1);
      dispatch(fetchWebTracking(tracking));
    }
  };
  const pageChange = current => {
    setCurrPage(current);
    dispatch(fetchOrderTracking('', '', current));
  };
  
  return (
    <>
      <div className="title">Order Tracking</div>
      <div className="header-sch">
        <div onClick={toggleTracking} className="header-button"><i className="icon-search" /></div>
        <input type="text" onChange={e => setTracking(e.target.value)} placeholder="Enter order number..." />
      </div>
      {
        orderTracking && !webTracking[0].orderNumber ?
          <>
            <div className="header">
              <div className="text-left">Tracking List ({orderTracking ? parseInt(orderTracking.totalRow[0].countRow).toLocaleString() : 0})</div>
              <div className="text-right">{currentDate()}</div>
            </div>
            {
              orderTracking && orderTracking.data.map((item, key) => 
                <Card key={key} orderNumber={item.orderNumber} latestStaus={item.latestStaus} deliveryDate={item.deliveryDate} qty={item.qty} />,
              )
            }
            <div className="yas-pagination">
              <Pagination
                activePage={currPage}
                itemsCountPerPage={Vars.pagination.itemsCountPerPage}
                pageRangeDisplayed={Vars.pagination.pageRangeDisplayed}
                totalItemsCount={orderTracking ? orderTracking.totalRow[0].countRow : 0}
                onChange={pageChange}/>
            </div>
          </> : null
      }
      {
        webTracking && webTracking[0].orderNumber ?
          <>
            <div className="header"><div className="text-left">Result</div></div>
            {
              webTracking ? webTracking.map((item, key) => 
                <Card key={key} orderNumber={item.orderNumber} latestStaus={item.latestStaus} deliveryDate={item.orderDateTime} qty={item.qty} />,
              ) : null
            }
          </> : null
      }
    </>
  );
};

export default OrderTrackings;
