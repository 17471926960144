import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import dotenv from 'dotenv';

import configStore from './stores/Config';
import App from './App';

dotenv.load();

ReactDOM.render(
  <Provider store={configStore()}><App /></Provider>,
  document.getElementById('root')
);
