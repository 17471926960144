import { get } from './Cookies';

export default {
  urlYAS: process.env.REACT_APP_DOMAIN_YAS_API ? process.env.REACT_APP_DOMAIN_YAS_API : '',
  urlUserServices: process.env.REACT_APP_DOMAIN_YAS_USER_SERVICE ? process.env.REACT_APP_DOMAIN_YAS_USER_SERVICE : '',
  paramsOrderTracking(tracking, orderDate, currPage) {
    return {
      url: '/post/OrderTrackingReport',
      param: {
        client: get('clients') === 'YAS' ? '' : get('clients'),
        orderDatefrom: orderDate ? orderDate : tracking ? '' : new Date().toISOString().substr(0,10),
        orderDateto: orderDate ? orderDate : tracking ? '' : new Date().toISOString().substr(0,10),
        deliverydatefrom: '',
        deliverydateto: '',
        deliveryZone: '',
        latestStatus: 'Picked,Packed,In Transit,Delivered,Undelivered',
        dayType: '',
        tracking: orderDate ? '' : tracking,
        orderBy: '',
        orderType: '',
        pageNumber: currPage ? currPage : 1,
        pageSize: 30,
      },
    };
  },
  paramsWebTracking(tracking) {
    return {
      url: '/post/WebTracking',
      param: { tracking },
    };
  },
  paramsOrderDetails(tracking) {
    return {
      url: '/post/OrderDetails',
      param: { client: '', tracking },
    };
  },
  pagination: {
    itemsCountPerPage: 30,
    pageRangeDisplayed: 6,
  },
};
