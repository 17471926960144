import React from 'react';
import { NavLink } from 'react-router-dom';
const Navbar = () => (
  <div className="Navbar">
    <div className="container">
      <NavLink to="/dashboard" activeClassName="active">
        <div className="nav-icon">
          <i className="icon-home" />
        </div>
        <small>Home</small>
      </NavLink>
      <NavLink to="/order-tracking" activeClassName="active">
        <div className="nav-icon">
          <i className="icon-location" />
        </div>
        <small>Order Tracking</small>
      </NavLink>
      <NavLink to="/search" activeClassName="active">
        <div className="nav-icon">
          <i className="icon-search" />
        </div>
        <small>Search</small>
      </NavLink>
      <NavLink to="/profile" activeClassName="active">
        <div className="nav-icon">
          <i className="icon-person" />
        </div>
        <small>Profile</small>
      </NavLink>
    </div>
  </div>
);

export default Navbar;
