export const pad = (n, len) => (new Array(len + 1).join('0') + n).slice(-len);
export const formatDate = date => {
  if (date) {
    const d = date.split(/[^0-9]/);
    const newDate = new Date(d[0],d[1] - 1,d[2],d[3],d[4],d[5]);
    return `${pad(newDate.getUTCDate(), 2)}/${pad(newDate.getUTCMonth() + 1, 2)}/${newDate.getUTCFullYear()} ${pad(newDate.getHours(), 2)}:${pad(newDate.getUTCMinutes(), 2)}`;
  } else {
    return '-';
  }
};
export const currentDate = inDay => {
  const date = inDay ? inDay : new Date();
  const month_names = ['January','February','March', 'April','May','June', 'July','August','September', 'October','November','December'];

  const day = date.getDate();
  const month_index = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${month_names[month_index]} ${year}`;
};
export const checkTrackingType = status => {
  if (!status) {return;}
  switch (status.toLowerCase()) {
    case 'ready for ship':
      return 'ready-for-ship';
    case 'picked':
      return 'picked';
    case 'packed':
      return 'packed';
    case 'in transit':
      return 'in-transit';
    case 'received order':
      return 'received-order';
    case 'delivered':
      return 'delivered';
    case 'undelivered':
      return 'undelivered';
    default:
      return;
  }
};
export const checkTrackingIcon = icons => {
  if (!icons) {return;}
  switch (icons.toLowerCase()) {
    case 'ready for ship':
      return 'icon-user';
    case 'picked':
      return 'icon-pick';
    case 'packed':
      return 'icon-pack';
    case 'in transit':
      return 'icon-intransit';
    case 'received order':
      return 'icon-check';
    case 'delivered':
      return 'icon-delivered';
    case 'undelivered':
      return 'icon-undelivered';
    default:
      return 'icon-dashboard';
  }
};

export const checkNull = data => data ? data : '-';
