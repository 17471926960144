import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'react-js-pagination';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import Vars from '../commons/Variables';
import { Card } from '../components';
import { currentDate } from './../commons/Function';
import { fetchOrderTracking } from './../stores/Actions';

const Search = () => {
  const [currPage, setCurrPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [orderTracking, setOrderTracking] = useState();
  const [orderDate, setOrderDate] = useState();
  const getOrderTracking = useSelector(({ getOrderTracking }) => getOrderTracking.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getOrderTracking) { setOrderTracking(getOrderTracking); }
  }, [getOrderTracking]);
  const toggleCalendar = () => setModal(true);
  const handleDayClick = (day, modifiers) => {
    if (modifiers.disabled) { return; }
    setOrderDate(day);
  };
  const toggleSch = () => {
    setModal(false);
    setCurrPage(1);
    dispatch(fetchOrderTracking('', orderDate));
  };
  const pageChange = current => {
    setCurrPage(current);
    dispatch(fetchOrderTracking('', '', current));
  };
  
  return (
    <>
      <div className="title">Search</div>
      <div className="header-sch" onClick={toggleCalendar}>
        <div className="input">{orderDate ? currentDate(orderDate) : 'Select Date'}</div>
        <div className="header-button"><i className="icon-calendar" /></div>
      </div>
      {
        orderTracking ?
          <>
            <div className="header">
              <div className="text-left">Tracking List ({orderTracking ? parseInt(orderTracking.totalRow[0].countRow).toLocaleString() : 0})</div>
              <div className="text-right">{currentDate(orderDate)}</div>
            </div>
            {
              orderTracking ? orderTracking.data.map((item, key) =>
                <Card key={key} orderNumber={item.orderNumber} latestStaus={item.latestStaus} deliveryDate={item.deliveryDate} qty={item.qty} />,
              ) : null
            }
            <div className="yas-pagination">
              <Pagination
                activePage={currPage}
                itemsCountPerPage={Vars.pagination.itemsCountPerPage}
                pageRangeDisplayed={Vars.pagination.pageRangeDisplayed}
                totalItemsCount={orderTracking ? orderTracking.totalRow[0].countRow : 0}
                onChange={pageChange}/>
            </div>
          </> : null
      }

      {
        modal ? 
          <div className="modal-calendar">
            <div className="modal-content">
              <div className="modal-title">SELECT DATA</div>
              <DayPicker 
                disabledDays={[{ after: new Date() }]}
                selectedDays={orderDate} 
                onDayClick={handleDayClick} />
              <div className="modal-footer">
                <button onClick={toggleSch}>SEARCH</button>
              </div>
            </div>
          </div> : null
      }
    </>
  );
};

export default Search;
