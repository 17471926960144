import Vars from './../commons/Variables';
import Cookie from './../commons/Cookies';
import { logout } from './Types';

const fetchRefreshToken = async () => {
  const token = Cookie.get('token');
  try {
    const res = await fetch(`${Vars.urlUserServices}/users/refresh-token`, {
      method: 'POST',
      body: JSON.stringify({ ttl: 21600 }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': token ? token : null,
      }),
    });
    if (res.status === 200) {
      return await Cookie.create('token', token, 3);
    } else if (res.status === 401) {
      return window.location = '/login';
    } else if (res.status === 500 || res.status === 502) {
      return window.location = '/warning';
    }
    return;
  } catch (err) {
    return;
  }
};

const checkStatus = async (dispatch, type, res) => {
  try {
    const data = await res.json();
    if (res.status === 200 || res.status === 201) {
      if (type.success === logout.success) {
        await Cookie.remove('token');
      }
      fetchRefreshToken();
      return dispatch({ type: type.success, data, status: res.status });
    } else if (res.status === 401) {
      window.location = '/login';
    } else if (res.status === 500 || res.status === 502) {
      window.location = '/warning';
    }
    return dispatch({
      type: type.failure,
      data: null,
      status: res.status ? res.status : res,
    });
  } catch (error) {
    return dispatch({
      type: type.success,
      data: null,
      status: 204,
    });
  }
};

function error(dispatch, type) {
  dispatch({
    type: type.failure,
    data: null,
    status: 500,
  });
}
export const get = async (dispatch, type, url, params) => {
  dispatch({ type: type.loading, status: 'PENDING' });
  try {
    const paramsFormat = params ? new URLSearchParams(Object.entries(params)).toString() : '';
    const res = await fetch(`${url + paramsFormat}`, {
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
    });
    checkStatus(dispatch, type, res);
  } catch (err) {
    return error(dispatch, type);
  }
};
export const post = async (dispatch, type, url, params, isToken) => {
  dispatch({ type: type.loading, status: 'PENDING' });
  try {
    const headers = await new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    });
    if (isToken) { await headers.append('Authorization', Cookie.get('token')); }
    
    const res = await fetch(`${url}`, {
      method: 'POST',
      body: params ? JSON.stringify(params) : null,
      headers,
    });
    
    checkStatus(dispatch, type, res);
  } catch (err) {
    return error(dispatch, type);
  }
};

/**
 * Documentation
 * @param {string} dispatch only dispatch method
 * @param {Object} type only object { success | failure }
 * @param {string} url only url '/example'
 * @param {Object} params only object
 */
export const login = async (dispatch, type, url, params) => {
  dispatch({ type: type.loading, status: 'PENDING' });
  try {
    const res = await fetch(`${url}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const data = await res.json();

    if (res.status === 200 || res.status === 201) {
      await Cookie.create('token', data.token, 3);
      return await dispatch({
        type: type.success,
        data,
        status: res.status,
      });
    }

    return dispatch({
      type: type.failure,
      data: null,
      status: res.status ? res.status : res,
    });
  } catch (err) {
    return error(dispatch, type);
  }
};

export default { get, post, login }
;
