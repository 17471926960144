const login = { success: 'LOGIN_SUCCESS', failure: 'LOGIN_FAILURE', loading: 'LOGIN_LOADING' };
export const logout = { success: 'LOGOUT_SUCCESS', failure: 'LOGOUT_FAILURE', loading: 'LOGOUT_LOADING' };
const profile = { success: 'PROFILE_SUCCESS', failure: 'PROFILE_FAILURE', loading: 'PROFILE_LOADING' };
const forgot = { success: 'FORGOT_SUCCESS', failure: 'FORGOT_FAILURE', loading: 'FORGOT_LOADING' };
const orderTracking = { success: 'ORDER_TRACKING_SUCCESS', failure: 'ORDER_TRACKING_FAILURE', loading: 'ORDER_TRACKING_LOADING' };
const webTracking = { success: 'WEB_TRACKING_SUCCESS', failure: 'WEB_TRACKING_FAILURE', loading: 'WEB_TRACKING_LOADING' };
const orderDetails = { success: 'ORDER_DETAILS_SUCCESS', failure: 'ORDER_DETAILS_FAILURE', loading: 'ORDER_DETAILS_LOADING' };

export default {
  login,
  logout,
  profile,
  forgot,
  orderTracking,
  webTracking,
  orderDetails,
};
