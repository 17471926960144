import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { checkNull, formatDate } from '../commons/Function';
import { fetchWebTracking, fetchOrderDetails } from './../stores/Actions';
import '../scss/OrderTrackingDetail.scss';

const OrderTrackingDetail = ({ match, history }) => {
  const [orderNo, setOrderNo] = useState(0);
  const [webTracking, setWebTracking] = useState({
    data: [{ customerName: '', awb: '', address: '' }],
    status: [
      { orderDateTime: null },
      { orderDateTime: null },
      { orderDateTime: null },
      { orderDateTime: null },
      { orderDateTime: null },
    ],
  });
  const [orderDetails, setOrderDetails] = useState();
  const getWebTracking = useSelector(({ getWebTracking }) => getWebTracking.data);
  const getOrderDetails = useSelector(({ getOrderDetails }) => getOrderDetails.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match) { setOrderNo(match.params.orderNumber); }
  }, [match]);
  useEffect(() => {
    if (getWebTracking) { setWebTracking(getWebTracking); }
  }, [getWebTracking]);
  useEffect(() => {
    if (getOrderDetails) { setOrderDetails(getOrderDetails.data); }
  }, [getOrderDetails]);
  useEffect(() => {
    if (orderNo) {
      dispatch(fetchWebTracking(orderNo));
      dispatch(fetchOrderDetails(orderNo));
    }
  }, [orderNo]);

  const checkStatusType = (item, nextItem) => {
    if (item && nextItem) { return 'checked'; }
    if (item) { return 'active'; }
    return '';
  };
  const toggleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="title">
        {/* <Link to="/dashboard"><i className="icon-arrow-left" /></Link> Order Tracking</div> */}
        <i className="icon-arrow-left" onClick={toggleGoBack} /> Order Tracking</div>
      <div className="box-content-tracking">
        <div className="box-content-header">
          <div className="ct-left">
            <small>Order number</small>
            <p>{orderNo}</p>
          </div>
          <div className="ct-right"><i className="icon-menu" /></div>
        </div>
        <div className="box-content-detail">
          <div className="section-detail">
            <div className="ct-left">
              <small>Customer Name</small>
              <p>{checkNull(webTracking.data[0].customerName)}</p>
            </div>
            <div className="ct-right">
              <small>AWB No.</small>
              <p>{checkNull(webTracking.data[0].awb)}</p>
            </div>
          </div>
          <div className="section-detail">
            <div className="ct-left">
              <small>Address</small>
              <p>{checkNull(webTracking.data[0].address)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="box-content">
        <p className="title">Order status</p>
        <div className="box-tracking-statuses">
          <div className={`status-picked ${checkStatusType(webTracking.status[0].orderDateTime, webTracking.status[1].orderDateTime)}`}>
            <div className="status-icon">
              {
                checkStatusType(webTracking.status[0].orderDateTime, webTracking.status[1].orderDateTime) === 'checked'
                  ? <i className="icon-check"/>
                  : <i className="icon-pick"/>
              }
            </div>
            <p>Pick</p>
            <small>{formatDate(webTracking.status[0].orderDateTime)}</small>
          </div>
          <div className={`status-packed ${checkStatusType(webTracking.status[1].orderDateTime, webTracking.status[2].orderDateTime)}`}>
            <div className="status-icon">
              {
                checkStatusType(webTracking.status[1].orderDateTime, webTracking.status[2].orderDateTime) === 'checked'
                  ? <i className="icon-check"/>
                  : <i className="icon-pack"/>
              }
            </div>
            <p>Pack</p>
            <small>{formatDate(webTracking.status[1].orderDateTime)}</small>
          </div>
          <div className={`status-in-transit ${checkStatusType(webTracking.status[2].orderDateTime, webTracking.status[3].orderDateTime)}`}>
            <div className="status-icon">
              {
                checkStatusType(webTracking.status[2].orderDateTime, webTracking.status[3].orderDateTime) === 'checked'
                  ? <i className="icon-check"/>
                  : <i className="icon-intransit"/>
              }
            </div>
            <p>In transit</p>
            <small>{formatDate(webTracking.status[2].orderDateTime)}</small>
          </div>
          {
            !webTracking.status[4].orderDateTime 
              ? <div className={`status-delivered ${checkStatusType(webTracking.status[3].orderDateTime, webTracking.status[4].orderDateTime)}`}>
                <div className="status-icon">
                  {
                    checkStatusType(webTracking.status[3].orderDateTime, webTracking.status[4].orderDateTime) === 'checked'
                    || checkStatusType(webTracking.status[3].orderDateTime, webTracking.status[4].orderDateTime) === 'active'
                      ? <i className="icon-check"/>
                      : <i className="icon-delivered"/>
                  }
                </div>
                <p>Delivered</p>
                <small>{formatDate(webTracking.status[3].orderDateTime)}</small>
              </div>
              : <div className="status-undelivered">
                <div className="status-icon"><i className="icon-undelivered"/></div>
                <p>Undelivered</p>
                <small>{formatDate(webTracking.status[3].orderDateTime)}</small>
              </div>
          }
        </div>
      </div>
      <div className="box-content">
        <p className="title">Order detail</p>
        <div className="order-tracking-detail">
          <div className="order-tracking-detail-header">
            <div className="text-left">Product</div>
            <div className="text-right">Qty.</div>
          </div>
          {
            orderDetails ? orderDetails.map((item, key) => (
              <div key={key} className="order-tracking-detail-body">
                <div className="text-left">{item.productNumber}</div>
                <div className="text-right">{item.qty}</div>
              </div>
            )) : null
          }
        </div>
      </div>
    </>
  );
};

OrderTrackingDetail.prototype = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderNumber: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
};

export default OrderTrackingDetail;
